.buttonConfigAntd {
    border-radius: 4px;
}

.spinConfigAntd {
    max-height: none !important;
}

.tableConfigAntd {
  overflow-x: auto;
}

.ant-radio-wrapper {
  margin-inline-end: 0 !important;
}
