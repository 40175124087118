@import '../node_modules/prismjs/themes/prism-tomorrow';

.spec p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 23px;
}

.spec h4, .spec h3, .spec h5 {
  margin-bottom: 0;
}

.spec h4, .spec h3, .spec h2, .spec h5 {
  color: black
}

.p-icon {
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}

.btn-dashed {
  border-style: dashed !important;
}

.form-control[readonly] {
  opacity: 1
}

.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #1C3BBE !important;
}

.flatpickr-day.week.selected {
  border-radius: 0 !important;
  -webkit-box-shadow: -5px 0 0 #1C3BBE, 5px 0 0 #1C3BBE !important;
  box-shadow: -5px 0 0 #1C3BBE, 5px 0 0 #1C3BBE !important;
}

.flatpickr-day.endRange.nextMonthDay {
  background: #1C3BBE !important;
  border-color: #1C3BBE !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #1C3BBE !important;
  box-shadow: -10px 0 0 #1C3BBE !important;
}

.flatpickr-input {
  background-color: #ffffff !important;
}

.form-control:disabled {
  background-color: #efefef !important;
}

.flatpickr-day.selected, [dir] .flatpickr-day.startRange, [dir] .flatpickr-day.endRange, [dir] .flatpickr-day.selected.inRange, [dir] .flatpickr-day.startRange.inRange, [dir] .flatpickr-day.endRange.inRange, [dir] .flatpickr-day.selected:focus, [dir] .flatpickr-day.startRange:focus, [dir] .flatpickr-day.endRange:focus, [dir] .flatpickr-day.selected:hover, [dir] .flatpickr-day.startRange:hover, [dir] .flatpickr-day.endRange:hover, [dir] .flatpickr-day.selected.prevMonthDay, [dir] .flatpickr-day.startRange.prevMonthDay, [dir] .flatpickr-day.endRange.prevMonthDay, [dir] .flatpickr-day.selected.nextMonthDay, [dir] .flatpickr-day.startRange.nextMonthDay, [dir] .flatpickr-day.endRange.nextMonthDay {
  background: #1C3BBE !important;
  border-color: #1C3BBE !important;
}

.document-field-label {
  margin: 12px 0 4px !important;
}

.edi-table {
  th, td {
    padding: 0.3rem !important
  }
}

.fs-tiny {
  font-size: 12px;
}

.tabled-field {
  margin-top: 1rem
}

.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0 !important;
}

.header-navbar {
  min-height: 3rem;
}

.content.app-content {
 // padding: 6rem !important;
}

.width-15 {
  width: 15%;
  max-width: 15%;
}

.width-10 {
  width: 10%;
  max-width: 10%;
  display: inline-flex;
}

.width-85 {
  width: 85%;
  max-width: 85%;
}

.cursor-pointer{
  cursor: pointer;
}

.position-static {
  position: static;
}

.pb-5px {
  padding-bottom: 5px;
}

.mb-5px {
  margin-bottom: 5px;
}

.pt-5px {
  padding-top: 5px;
}

.mt-5px {
  margin-top: 5px;
}
.modal, .modal-open {
    overflow: hidden;
}

input[type=number] {
  -moz-appearance: textfield;
}
